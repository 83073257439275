<template>
  <v-app>
    <v-app-bar
        app
        flat
        hide-on-scroll
        v-if="$route.name != 'Login' && $route.name != 'Register'"
    >
      <template v-slot:extension>
        <v-tabs
            grow
            color="grey darken-1"
        >
          <v-tab
              to="/catalog"
          >
            Katalog
          </v-tab>
          <v-tab
              v-if="$store.getters.isAuthorized"
              to="/orders"
          >
            Zamówienia
          </v-tab>
          <v-tab
              to="/help"
          >
            Pomoc
          </v-tab>
        </v-tabs>
      </template>

<!--      <v-avatar-->
<!--          class="hidden-sm-and-down"-->
<!--          color="grey darken-1 shrink"-->
<!--          size="32"-->
<!--      ></v-avatar>-->
      <div>
        <div class="text--secondary">Witam</div>
        <div class="d-sm-none">
          {{ $store.getters.name|truncate(10) }}
        </div>
        <div class="d-none d-sm-block">
          {{ $store.getters.name|truncate(45) }}
        </div>
      </div>
      <v-spacer></v-spacer>
      <div class="d-flex align-center">
        <template v-if="$store.getters.isAuthorized">
          <template v-if="$store.getters.verified">
            <v-chip class="ml-5 mr-1 pl-5 pr-6 success" to="/pay">
              {{ $store.getters.balance }} ZL
            </v-chip>
            <v-chip class="ml-5 mr-1 pl-5 pr-6 success" to="/points">
              {{ $store.getters.points }} Baly
            </v-chip>
            <v-chip class="ml-1 mr-10 pl-5 pr-5" color="error" to="/points">
              Nowe!🥳 Baly
  <!--              {{ $store.getters.points }}-->
            </v-chip>
          </template>
          <v-chip class="ml-5 mr-10 pl-5 pr-6 yellow black--text" to="/pay" v-if="!$store.getters.verified">
            Nie zweryfikowany 🤷
          </v-chip>
          <v-btn class="logout" fab small @click="logout">
            <v-icon class="d-block mt-n1">
              mdi-exit-to-app
            </v-icon>
          </v-btn>
        </template>
        <template v-if="!$store.getters.isAuthorized">
          <v-chip class="ml-5 mr-10 pl-5 pr-6 yellow black--text" to="/register" v-if="!$store.getters.verified">
            Nieautoryzowany 😔
          </v-chip>
          <v-btn class="login" fab small to="/register">
            <v-icon class="d-block mt-n1" color="success">
              mdi-login
            </v-icon>
          </v-btn>
        </template>
      </div>
    </v-app-bar>
    <v-main>
      <router-view></router-view>
    </v-main>
    <v-snackbar
        v-model="showSnackbar"
    >
      {{ snackbarText }}

      <template v-slot:action="{ attrs }">
        <v-btn
            color="pink"
            text
            v-bind="attrs"
            @click="showSnackbar = false"
        >
          Zamkni
        </v-btn>
      </template>
    </v-snackbar>
  </v-app>
</template>

<script>

export default {
  name: 'App',
  data: () => ({
    timeout: true
  }),
  filters: {
    truncate: function(value, len) {
      if (value.length > len) {
        value = value.substring(0, len - 3) + '...';
      }
      return value
    }
  },
  components: {
    // HelloWorld,
  },
  watch: {
    showSnackbar(aft, bef) {
      if (bef == false && aft == true) {
        setTimeout(() => {
          if (this.showSnackbar)
            this.showSnackbar = false
          this.timeout = false
          this.$nextTick(() => {
            this.timeout = true
          })
        }, 3000)
      }
    }
  },
  computed: {
    snackbarText() {
      return this.$store.getters.firstError
    },
    showSnackbar: {
      get() {
        return !!this.$store.getters.firstError && this.timeout
      },
      set(val) {
        this.$store.commit("shiftError");
      }
    },
    beforeNewYear() {
      return new Date('01.01.2024') - new Date().getTime() > 0
    },
  },
  methods: {
    async logout() {
      this.$router.push("/login")
      await this.$store.dispatch("logout")
    }
  },
  mounted() {
    if (this.beforeNewYear) {
      setTimeout(() => {
        this.$store.commit("addError", "W Sylwestra 31 grudnia 2023 roku możliwa będzie dostawa pizzy naszym kurierem. Złóż zamówienie telefonicznie, zapłać Blikiem, a nasza pizza jak najszybciej trafi na Twój świąteczny stół. Dodatkowy koszt 10 zł")
      }, 5000)
    }
  },
  created() {
    if (this.$store.getters.token) {
      this.$store.dispatch("loadProfile")
    }
  }
};
</script>

<style>
.v-slide-group__prev.v-slide-group__prev--disabled {
  display: none !important;
}
.v-main {
  background: rgba(0, 0, 0, .65) url("../public/img/pizza-pattern.jpg");
  background-blend-mode: darken;

  background-repeat: repeat;
  background-size: 25%;
}
.v-app-bar .v-toolbar__content {
  overflow-x: auto;
}
</style>
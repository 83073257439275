<template>
  <v-container>
    <v-sheet
        elevation="1"
        class="px-3 py-3 rounded mb-3"
        align="center"
    >
      Jak zdobyć zamówienie? Przyjdź do pizzerii i odbierz zamówienie.<br>
      <template v-if="$store.getters.isAuthorized && hasConfirmedOrders">
        Aby to zrobić, pokaż kod:
<!--        {{ $store.getters.userId }}-->
        <v-img
            v-if="codeUri"
            :src="codeUri"
            style="image-rendering: pixelated;"
            max-width="420"
        ></v-img>
      </template>
    </v-sheet>
    <v-progress-circular
        v-if="!$store.getters.orders_loaded"
        indeterminate
        color="primary"
        class="mx-auto d-block"
    ></v-progress-circular>
    <div v-if="$store.getters.orders_loaded && !$store.getters.orders.length" class="text-center">Empty!</div>
    <v-row>
      <v-col
          cols="0"
          xl="4"
          lg="4"
          md="2"
          class="d-md-block d-sm-none"
      ></v-col>
      <v-col
          cols="12"
          xl="4"
          lg="4"
          md="8"
          sm="12"
      >
        <v-expansion-panels>
          <v-expansion-panel
              v-for="(order,i) in notNewOrders"
              :key="i"
          >
            <v-expansion-panel-header>
              <v-row no-gutters>
                <v-col cols="3" class="text-left pr-3">
                  {{ statusTranslator(order.status) }}
                </v-col>
                <v-col cols="2" class="text-left">
                  {{ order.total ?? 0 }} PLN
                </v-col>
                <v-col cols="2" class="text-left">
                  {{ modeTranslator(order.mode) }}
                </v-col>
              </v-row>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <v-row
                  class="mt-3"
                  no-gutters
                  v-for="(orderProduct, orderProductIndex) in order.products"
                  :key="orderProductIndex"
              >
                <v-col cols="3">
                  <v-avatar>
                    <v-img :src="`${$store.getters.baseUrl}/${orderProduct.image}`"></v-img>
                  </v-avatar>
                </v-col>
                <v-col cols="6">
                  {{ orderProduct.title }}
                </v-col>
                <v-col cols="3" class="text-right">
                  {{ orderProduct.quantity }} * {{ orderProduct.price }} PLN
                </v-col>
              </v-row>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-col>
      <v-col
          cols="4"
          xl="4"
          lg="4"
          md="2"
          class="d-md-block d-sm-none"
      ></v-col>
    </v-row>
  </v-container>
</template>

<script>
import QRCode from 'qrcode'

export default {
  data: () => ({
    codeUri: false,
  }),
  computed: {
    notNewOrders() {
      return this.$store.getters.orders.filter((order) => {return order.status != "new"})
    },
    hasConfirmedOrders() {
      return !!this.$store.getters.orders.filter((order) => {
        return order.status === "confirmed";
      }).length
    }
  },
  methods: {
    statusTranslator(status) {
      switch (status) {
        case "fulfilled":
          return "Zakonczonne";
        case "confirmed":
          return "Podtwierdzonne";
        case "new":
          return "Nowe";
        case "in_transit":
          return "W dostawie";
        case "locked":
          return "Przyjęty";
      }
      return status
    },
    modeTranslator(mode) {
      switch (mode) {
        case "points":
          return "Baly";
        case "PLN":
          return "PLN";
      }
      return mode
    }
  },
  mounted() {
    this.$store.dispatch("getOrders");
    let str = `Congratulations! You saw a secret (⌐■_■):${this.$store.getters.userId}`;
    QRCode.toDataURL(str, {
      color: {
        // dark: '#FFF',  // Blue dots
        // light: '#0000' // Transparent background
      }
    })
      .then(url => {
        this.codeUri = url;
      })
      .catch(err => {
        console.error(err)
      })
  }
}
</script>